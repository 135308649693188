<template>
	<app tabbar create-button @create="navigateTo(createPath)">
		<tab-list-m :tab="tabList" :current="tab" @change="updateList"></tab-list-m>
		<scroll-list-m ref="list">
			<template v-slot="{data}">
				<scroll-item-m v-if="currentTab == 1">
					<div class="name">{{data.organization_name}}</div>
					<div class="info">审核人手机号码：{{data.check_phone}}</div>
					<div class="info">子账号数量：{{data.user_count}}</div>
					<div class="info">创建人：{{data.create_user_name}}</div>
					<div class="info">创建时间：{{data.create_date_str}}</div>
					<button-list-m slot="button">
						<c-button @click="navigateTo('/organize_edit', {id: data.organization_id})">编辑</c-button>
						<c-button color="gray" hollow @click="deleteHandle(data)">删除</c-button>
					</button-list-m>
				</scroll-item-m>
				<scroll-item-m v-else>
					<div class="flex-between-center">
						<div class="name">{{data.user_realname}}</div>
						<div class="state"><span :style="{'color': data.state == '2' ? '#00B575' : '#E63633'}">{{data.state == '2' ? '启用' : '禁用'}}</span></div>
					</div>
					<div class="info">手机号码：{{data.phone}}</div>
					<div class="info">所属部门：{{data.yzdw_organization_name}}</div>
					<div class="info">浙政钉用户ID：{{data.zzd_userid}}</div>
					<div class="info">是否管理员：{{data.yzdw_is_manage == 2 ? '是' : '否'}}</div>
					<button-list-m slot="button">
						<c-button @click="navigateTo('/account_edit', {id: data.id})">编辑</c-button>
						<c-button color="gray" hollow @click="deleteHandle(data)">删除</c-button>
					</button-list-m>
				</scroll-item-m>
			</template>
		</scroll-list-m>
	</app>
</template>

<script>
	import {mapGetters} from 'vuex'
	
	export default {
		props: {
			tab: String
		},
		
		data() {
			return {
				tabList: [{
					type: 1,
					name: '组织架构',
					url: '/yzdw/organization/list'
				}, {
					type: 2,
					name: '子账号管理',
					url: '/yzdw/user/list'
				}],
				currentTab: 1
			}
		},
		
		computed: {
			...mapGetters(['projectState', 'personState']),
			
			createPath() {
				return this.currentTab == 1 ? '/organize_edit' : '/account_edit';
			}
		},
		
		methods: {
			updateList(data) {
				this.currentTab = data.type;
				this.$refs.list.load({
					url: data.url,
					data: data.param
				});
			},
			
			deleteHandle(data) {
				if (this.currentTab == 1) {
					this.$confirm({
						text: '确定要删除此组织吗？',
						resolve: () => {
							this.request({
								url: '/yzdw/organization/delete',
								data: {
									organization_id: data.organization_id
								},
								loading: true,
								success: () => {
									this.$message({
										text: '删除成功'
									});
									this.$refs.list.reload();
								}
							});
						}
					});
				} else {
					this.$confirm({
						text: '确定要删除此子账号吗？',
						resolve: () => {
							this.request({
								url: '/yzdw/user/delete',
								data: {
									user_id: data.id
								},
								loading: true,
								success: () => {
									this.$message({
										text: '删除成功'
									});
									this.$refs.list.reload();
								}
							});
						}
					});
				}
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>